// 
// card.scss
//

.card {
    margin-bottom: $grid-gutter-width;
}

.card-link + .card-link{
    /*rtl:ignore*/
    margin-left: $card-spacer-x;
    /*rtl:ignore*/
    margin-right: 0;
}

.card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}

.card-header {
    padding: 0.2rem 1.25rem !important;
}

// .card-footer {
    // padding: 0.2rem 1.25rem !important;
// }

.card-add {
    padding: 0.4rem 0.9em;
}

//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: $card-overlay-bg;
    cursor: progress;

    .card-portlets-loader {
        background-color: $card-overlay-color;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 3px;
        margin-left: - ($grid-gutter-width * 0.5);
        margin-top: - ($grid-gutter-width * 0.5);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// Custom card box
.card-box {
    background-color: $card-bg;
    padding: $card-spacer-x;
    box-shadow: $box-shadow-sm;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
}

.header-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
    text-transform: uppercase;
}

.header-title2 {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
    // text-transform: uppercase;
}

.employee-claim-subtitle {
    background-color: #0c686c;
    border: 2px solid #b28924;
    color: #b28924;

    p {
        font-size: 15px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
    }
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}

@mixin invoice-status {
    color: #fff;
    height: fit-content;
    // min-height: 0.4em;
    // width: fit-content;
    // width: 10rem;
    padding: 0.3em 1em;
    border-radius: 3em;
    p {
        // white-space: nowrap;
        // line-height: 2em;
        margin-bottom: 0;
        text-align: center;
    }
}


@mixin status-header-detail {
    color: #fff;
    height: fit-content;
    min-height: 2rem;
    padding: 0.3em 1em;
    border-radius: 3em;
        p {
        margin-bottom: 0;
        text-align: center;
    }
    display: flex;
    justify-content: center;
    align-items: center;
}
.status-default {
    @include status-header-detail;
}
.status-pending {
    @include status-header-detail;
    background-color: #E9AB13;
}
.status-complete {
    @include status-header-detail;
    background-color: #0029FF;
}
.status-reject {
    @include status-header-detail;
    background-color: #A30D11;
}
.invoice-input-miro-number {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-input-payment-date {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-payment {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-input-migo-ses {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval-warehouse-officer {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval-contract-owner {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval-sap-admin {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval-ap-admin {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval-tax {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval-finance-manager {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval-presdir {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-waiting-approval-treasury {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-upload-payment-advice {
    @include invoice-status;
    background-color: #E9AB13;
}
.invoice-approved {
    @include invoice-status;
    background-color: #009444;
}
.invoice-completed{
    @include invoice-status;
    background-color: #0029FF;
}
.invoice-bast-approved{
    @include invoice-status;
    background-color: #0029FF;
}
.invoice-paid-completed{
    @include invoice-status;
    background-color: #0029FF;
}
.invoice-paid{
    @include invoice-status;
    background-color: #0029FF;
}
.invoice-rejected{
    @include invoice-status;
    background-color: #A30D11;
}

.border-none {
    border: none;
}


// for dev
.bg-blue {
    background-color: #0066ff;
}
.bg-green {
    background-color: #1eff00b6;
}
.bg-yellow {
    background-color: #e1ff00e6;
}
.bg-red {
    background-color: #e63f3f;
}
.bg-gray {
    background-color: #c3c3c3;
}

.kpi-table {
    background-color: white;
    overflow: scroll;
    .first-col {
        min-width: 10rem;
    }
    table {
        min-width: 100%;
        border-collapse: collapse;
        thead {
            tr {
                th, td {
                    padding: 2px 4px;
                    min-width: 3rem;
                }
            }
        }
    }
    th, td {
        padding: 2px 4px;
        text-align: center;
        border: 1px solid black;
    }
}

.css-1omuo8w-MuiToolbar-root {
    z-index: 0 !important;
}
.css-qxov5u {
    z-index: 0 !important;
}

.hover-gold {
    &:hover {
        span {
            color: #b28924;
        }
    }
}

.custom-switch {
    margin-bottom: 0 !important;

    .form-check-input:checked {
        background-color: #007e7a;
        border-color: #007e7a;
    }
    .form-check-input {
        width: 3.2em;
        height: 1.5em;
    }
    .form-check-label {
        margin-left: 0.5em;
        padding: .2em;
        padding-top: 0.3em 
    }
}

.modal-approval-confirmation-class {
    .MuiDialog-container {
        .MuiPaper-root {
            overflow: hidden !important;
            width: 50vw;
        }
    }
}