// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// .ias-container {
//   font-size: smaller;
// }
.ias-table-1 {
  width: 100%;
  tr {  
    border-bottom: 1px dotted rgba(50, 49, 49, 0.13)
  }
}

.ias-table-2 {
  width: 100%;
  box-sizing: border-box;

  p {
    margin-bottom: 0;
  }
  td {
    height: 2em;
  }

  .ias-table-2-title {
    width: 10%;
    p {
      text-align: center;
    }
  }
  .ias-table-2-check {
    border: 2px solid rgb(24, 112, 180);
    width: 10%;
    p {
      text-align: center;
    }
  }
  .ias-table-2-desc {
    padding-left: 5%;
  }
}

.ias-table-3 {
  // width: 100%;
  // margin: 0 1.25rem;
  // border: 2px solid rgb(24, 112, 180);
  text-align: center;
  
  th,td {
    width: 33%;
    border: 3px solid rgb(10, 137, 234);
    text-wrap: suppress;
    word-wrap: break-word;
  }
  tr {
    td {
      height: 1.65rem;
      text-wrap:suppress;
      word-wrap: break-word;
  }
  }
}

.ias-table-5 div {
  min-height: 1.5rem;
}

.ias-table-3-1 {
  // width: auto;
  text-align: center;
  // margin: 0 1.25rem;

  td {
    border: 3px solid rgb(10, 137, 234);
  }
  th {
    width: 25%;
    border-color: rgb(10, 137, 234);
    border-style: solid;
    border-width: 0px 3px 3px 3px;
  }
}

.container-ias-table {
  // margin: 1rem 1.25rem 0 1.25rem;
  display: flex;
  justify-content: space-between;
}
.ias-table-4 {
  width: 70%;
  text-align: center;

  td,th {
    border: 3px solid rgb(10, 137, 234);
  }
}
.ias-table-5 {
  width: 25%;
  text-align: center;

  th,td {
    border: 3px solid rgb(10, 137, 234);
  }
}

.mySelect__value-container{
 height: 10px;
}

.cursor-pointer {
  cursor: pointer
}