// 
// pagination.scss
//


// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px;
        border: none;
    }
}

.page-item.active .page-link {
    background-color: #007E7A;
    border-color: #007E7A;
}